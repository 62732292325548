import CardSpacer from "@dashboard/components/CardSpacer";
import CardTitle from "@dashboard/components/CardTitle";
import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { fetch_from_backend } from "./lib";

export const DeployCard = ({ channels }: any) => {
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("default-channel");

  const onClick = () => {
    setLoading(true);
    fetch_from_backend(
      "deploy?channel=" + selectedChannel,
      data => {
        if (data.ok) {
          alert("Shop und Blog werden neu aufgeschaltet (Dauer: ca 15 min).");
        } else {
          alert("Fehler beim ausführen");
        }
        setLoading(false);
      },
      { method: "POST" },
    );
  };

  return (
    <Card>
      <CardTitle title="Neu aufschalten" />
      <CardContent>
        Shop und Blog neu aufschalten (Dauer ca. 15min)
        <CardSpacer />
        <select
          name="channels"
          id="channels"
          value={selectedChannel}
          onChange={e => setSelectedChannel(e.target.value)}
        >
          {channels &&
            channels.map((channel, index) => (
              <option key={index} value={channel.slug}>
                {channel.name}
              </option>
            ))}
        </select>
        <CardSpacer />
        <Button
          onClick={onClick}
          color="primary"
          variant="contained"
          data-test="add-product"
          disabled={loading}
        >
          <FormattedMessage
            defaultMessage="Starten"
            description="button"
            id="90qCS5"
          />{" "}
          {loading && <span>...</span>}
        </Button>
      </CardContent>
    </Card>
  );
};
