import CardSpacer from "@dashboard/components/CardSpacer";
import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import Grid from "@dashboard/components/Grid";
import {
  CardContent,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { fetch_from_backend, titleCase } from "./lib";

const ulStyle = { margin: "10px 0", padding: "0 0 0 25px" };
const tdStyle = { padding: "5px 15px" };

const useStyles = makeStyles(
  theme => ({
    jobHeading: {
      fontWeight: "bold",
    },
    root: {
      width: "100%",
    },
    secondaryJobHeading: {
      color: theme.palette.text.secondary,
    },
  }),
  { name: "import" },
);

const None = () => <div>-</div>;

const Files = ({ data, fileNames, caption }) => {
  const files = Object.entries(data).reduce((a, v) => {
    if (fileNames.indexOf(v[0]) >= 0) {
      a.push({ href: v[1], key: v[0] });
    }
    return a;
  }, []);

  return (
    <div>
      <Typography variant="caption">{caption}</Typography>
      {files.length === 0 ? (
        <None />
      ) : (
        <ul style={ulStyle}>
          {files.map(({ href, key }) => (
            <li key={key}>
              <Link href={href} target="_blank" rel="noreferrer">
                {titleCase(key)}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const DataTable = ({ data, caption }) => {
  const errors = [];

  const rows = Object.entries(data).reduce((a, v) => {
    if (v[0] === "_errors" && Array.isArray(v[1])) {
      errors.push(...v[1]);
    } else if (typeof v[1] === "object") {
      Object.entries(v[1]).forEach(subVal => {
        if (subVal[1]) {
          a.push({ title: titleCase(subVal[0]), value: subVal[1] });
        }
      });
    } else {
      a.push({ title: titleCase(v[0]), value: v[1] });
    }
    return a;
  }, []);

  rows.sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <div>
      <Typography variant="caption">{caption}</Typography>
      {errors.length > 0 && (
        <ul
          style={{
            ...ulStyle,
            backgroundColor: "#FE6E76",
            borderRadius: "4px",
            color: "#fff",
            fontWeight: "bold",
            padding: "10px 10px 10px 25px",
            width: "100%",
          }}
        >
          {errors.map((e, key) => (
            <li key={key}>{e}</li>
          ))}
        </ul>
      )}

      {rows.length > 0 || errors.length > 0 ? (
        <table style={{ marginTop: "10px", width: "100%" }}>
          <tbody>
            {rows.map(({ title, value }, key) => (
              <tr
                key={key}
                style={{
                  backgroundColor: key % 2 ? "#fff" : "rgb(238, 242, 238)",
                }}
              >
                <td style={tdStyle}>{title}</td>
                <td style={tdStyle} align="right">
                  {value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <None />
      )}
    </div>
  );
};

const ImportJob = ({ job, initialExpanded, handleDelete, handleReRun }) => {
  const classes = useStyles({ job });
  const [expanded, setExpanded] = React.useState(initialExpanded);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  const onDelete = () => {
    handleDelete(job);
  };

  const onReRun = () => {
    handleReRun(job);
  };

  return (
    <ExpansionPanel expanded={expanded} onChange={onExpand}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} id={`${job.id}`}>
        <Typography className={classes.jobHeading}>
          {`ID ${job.jobId} - ${job.channel}`} <br />
          <DateTime date={job.updatedAt} />
        </Typography>
        <Typography className={classes.secondaryJobHeading}>
          &nbsp;{`- ${job.user}`}
        </Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>
        <Stepper orientation="vertical" nonLinear>
          <Step active>
            <StepLabel>Upload</StepLabel>

            <StepContent>
              <Files
                data={job}
                caption="Files"
                fileNames={["erp_upload", "assortment_spreadsheet"]}
              />
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>Validation</StepLabel>
            <StepContent>
              {job.processed ? (
                <>
                  <Grid variant="uniform">
                    <div>
                      <DataTable
                        data={job.statistics["parser.statistics"]}
                        caption={"Statistics"}
                      />
                      <CardSpacer />
                      <Files
                        data={job.statistics.files}
                        caption="Files"
                        fileNames={[
                          "parse_valid_items",
                          "parse_not_found_in_abacus",
                          "parse_invalid_items",
                        ]}
                      />
                    </div>
                    <DataTable
                      data={job.statistics["parser.validator.errors"]}
                      caption={"Errors"}
                    />
                  </Grid>
                </>
              ) : (
                <CircularProgress />
              )}
            </StepContent>
          </Step>
          <Step active={job.processed}>
            <StepLabel>Import</StepLabel>
            <StepContent>
              {job.processed && (
                <Grid variant="uniform">
                  <DataTable
                    data={job.statistics["importer.statistics"]}
                    caption={"Statistics"}
                  />
                </Grid>
              )}
            </StepContent>
          </Step>
        </Stepper>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        <>
          <Button size="small" color="secondary" onClick={onDelete}>
            Löschen
          </Button>
          <Button size="small" color="primary" onClick={onReRun}>
            Eruneut Importieren
          </Button>
        </>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

export const ImportSection = ({ initialJobs, channels }: any) => {
  const [upload, setUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState(initialJobs);
  const [selectedChannel, setSelectedChannel] = useState("default-channel");

  const onChange = event => {
    setUpload(event.target.files[0]);
  };

  useEffect(() => {
    setJobs(initialJobs);
  }, [initialJobs.length]);

  const onClick = () => {
    setLoading(true);
    const data = new FormData();
    data.append("file", upload);

    fetch_from_backend(
      "deploy-excel?channel=" + selectedChannel,
      data => {
        if (data.ok) {
          setJobs(data.jobs);
          alert(
            "Das Excel wird importiert und danach werden Shop und Blog neu aufgeschaltet (Dauer: ca 10 min).",
          );
        } else {
          alert("Fehler beim ausführen");
        }
        setUpload("");
        setLoading(false);
      },
      { body: data, method: "POST" },
    );
  };

  const handleDelete = async job => {
    if (
      confirm(
        `Achtung: Wollen sie den Job ${job.jobId} vom ${moment(
          job.updatedAt,
        ).format(
          "DD.MM.YY, hh:mm",
        )} wirklich löschen?\n\nDies hat keinen Einfluss auf die Produkte.`,
      )
    ) {
      setLoading(true);
      fetch_from_backend(
        `import-jobs-delete?id=${job.jobId}`,
        data => {
          if (data.ok) {
            setJobs(data.jobs);
          } else {
            alert("Fehler beim ausführen");
          }
          setLoading(false);
        },
        { method: "POST" },
      );
    }
  };

  const handleReRun = job => {
    if (
      confirm(
        `Achtung: Wollen Sie den Job ${job.jobId} vom ${moment(
          job.updatedAt,
        ).format(
          "DD.MM.YY, hh:mm",
        )} wirklich nochmals ausführen?\n\nDanch werden Shop und Blog neu aufgeschaltet (Dauer: ca 10 min).`,
      )
    ) {
      setLoading(true);
      fetch_from_backend(
        `import-jobs-re-run-job?id=${job.jobId}`,
        data => {
          if (data.ok) {
            setJobs(data.jobs);
            alert("Der Job wird nochmals ausgeführt.");
          } else {
            alert("Fehler beim ausführen");
          }
          setLoading(false);
        },
        { method: "POST" },
      );
    }
  };

  return (
    <>
      <Card>
        <CardTitle title="Abacus Import" />
        <CardContent>
          Abacus Excel importieren, abgleichen mit Edvin Sortiment Spreadsheet
          und Shop/Blog neu aufschalten (Dauer ca. 15min)
          <CardSpacer />
          <select
            name="channels"
            id="channels"
            value={selectedChannel}
            onChange={e => setSelectedChannel(e.target.value)}
          >
            {channels &&
              channels.map((channel, index) => (
                <option key={index} value={channel.slug}>
                  {channel.name}
                </option>
              ))}
          </select>
          <CardSpacer />
          <input
            type="file"
            name="file"
            onChange={onChange}
            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <CardSpacer />
          <Button
            onClick={onClick}
            color="primary"
            variant="contained"
            data-test="add-product"
            disabled={!upload || loading}
          >
            <FormattedMessage
              id="2Xrx5m"
              defaultMessage="Importieren"
              description="button"
            />{" "}
            {loading && <span>...</span>}
          </Button>
          <CardSpacer />
          {jobs.length === 0 && <CircularProgress />}
          {jobs.map((job, index) => (
            <ImportJob
              job={job}
              key={index}
              initialExpanded={index === 0 && !job.processed}
              handleDelete={handleDelete}
              handleReRun={handleReRun}
            />
          ))}
        </CardContent>
      </Card>
      <CardSpacer />
    </>
  );
};
