import { CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardSpacer from "@dashboard/components/CardSpacer";
import CardTitle from "@dashboard/components/CardTitle";
import { DateTime } from "@dashboard/components/Date";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { fetch_from_backend, titleCase } from "./lib";

const tdStyle = {
  padding: "5px 10px",
  textAlign: "left" as const,
  verticalAlign: "top"
};

const ulStyle = {
  margin: "0 0 15px 0",
  padding: "0 0 0 25px"
};

export const NestedList = ({ data }) => {

  const renderChild = child => { 
    if (!child) {
      return <></>
    }
    return (
      <>
        {Object.keys(child).map(key => {
          const val = child[key];
          return (
            <li key={key}>
              {titleCase(key)}:{" "}
              {typeof val === "object" ? (
                <ul style={ulStyle}>{renderChild(val)}</ul>
              ) : (
                <>{val}</>
              )}
            </li>
          );
        })}
      </>
  )};

  return (
    <div>
      <ul style={ulStyle}>{renderChild(data)}</ul>
    </div>
  );
};

const ErrorBadge = ({ text }) => (
  <div
    style={{
      backgroundColor: "#FE6E76",
      borderRadius: "5px",
      color: "#fff",
      fontWeight: "bold",
      padding: "1px 4px",
      textAlign: "center"
    }}
  >
    {text}
  </div>
);

const Message = ({ message }) => (
  <>
    <td style={tdStyle}>
      <DateTime date={message.created_at} />
    </td>
    <td style={tdStyle}>
      {message.type === "Error" ? (
        <ErrorBadge text={message.type} />
      ) : (
        message.type
      )}
    </td>
    <td style={tdStyle}>{message.system}</td>
    <td style={tdStyle}>{message.text}</td>
    <td style={tdStyle}>
      <NestedList data={message.data} />
    </td>
  </>
);

export const DeployMessagesCard = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const onClear = () => {
    setLoading(true);
    fetch_from_backend(
      "deploy-clear-messages",
      data => {
        if (data.ok) {
          setMessages([]);
        } else {
          alert("Fehler beim ausführen");
        }
        setLoading(false);
      },
      { method: "POST" }
    );
  };

  useEffect(() => {
    fetch_from_backend("deploy-get-messages", data => {
      setMessages(data.messages || []);
    });
  }, []);

  return (
    <Card>
      <CardTitle title="Deploy Messages" />
      <CardContent>
        {messages.length > 0 ? (
          <table style={{ marginTop: "10px", width: "100%" }}>
            <thead>
              <tr>
                <th style={tdStyle}>Date</th>
                <th style={tdStyle}>Type</th>
                <th style={tdStyle}>System</th>
                <th style={tdStyle}>Text</th>
                <th style={tdStyle}>Data</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message, key) => (
                <tr
                  key={key}
                  style={{
                    backgroundColor: key % 2 ? "#fff" : "rgb(238, 242, 238)"
                  }}
                >
                  <Message message={message} key={key} />
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No Messages</div>
        )}

        <CardSpacer />
        <Button
          onClick={onClear}
          color="primary"
          variant="contained"
          data-test="add-product"
          disabled={loading}
        >
          <FormattedMessage defaultMessage="Clear" description="button" id="DeployMessagesCard" />{" "}
          {loading && <span>...</span>}
        </Button>
      </CardContent>
    </Card>
  );
};
