import { createFetch } from "@saleor/sdk";

const API_SERVER_URI = process.env.API_URL.replace(/graphql\/{0,1}/i, "");
const tokenUrl = API_SERVER_URI + "generate-access-token";

export const fetch_from_backend = (
  location,
  onSuccess,
  options = {},
  parseFn = response => response.json(),
) => {
  const authorizedFetch = createFetch();

  authorizedFetch(tokenUrl, {
    ...options,
  })
    .then(response => response.json())
    .then(({ token }) => {
      authorizedFetch(`${API_SERVER_URI}${location}`, {
        headers: {
          "Api-Authorization": token,
        },
        ...options,
      })
        .then(parseFn)
        .then(onSuccess)
        .catch(
          error => console.error(error), // Handle the error response object
        );
    });
};

export const downloadFile = async (
  url,
  options = {},
  filename = null,
  fileType = "application/pdf",
) => {
  fetch_from_backend(
    url,
    data => {
      const base64 = data.base64Data;

      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: fileType });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");

      a.href = url;

      if (filename) {
        a.download = filename;
      }

      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    options,
  );
};

export const titleCase = str =>
  str
    ? str
        .split("_")
        .map(word => (word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : ""))
        .join(" ")
        .replace("Parse ", "")
    : "";
