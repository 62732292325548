import { Button } from "@dashboard/components/Button";
import { downloadFile } from "./lib";
import React from "react";


export const GiftCardDownload: any = ({ giftCard } : any) => {
    const onPdfClick = () => {
        downloadFile(
            `gift-card-pdf`,
            { method: "POST", body: JSON.stringify({ id: giftCard.id }) },
            `edvin-${giftCard.last4CodeChars}.pdf`
        );
    };
    
    return (
        <Button variant="primary" onClick={onPdfClick} >
          Download
        </Button>
    )
}