import { TopNav } from "@dashboard/components/AppLayout";
import CardSpacer from "@dashboard/components/CardSpacer";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import React, { useEffect, useState } from "react";

import { DeployCard } from "./deploy";
import { DeployMessagesCard } from "./deployMessages";
import { ImportSection } from "./import";
import { fetch_from_backend } from "./lib";

const Component = () => {
  const [channels, setChannels] = useState([]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetch_from_backend("import-jobs", data => {
      if (data.jobs) {
        setJobs(data.jobs);
      }
      if (data.channels) {
        setChannels(data.channels);
      }
    });
  }, []);

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title={"Import & Deploy"}></TopNav>
      <DetailPageLayout.Content data-test-id="configuration-menu">
        <DeployCard channels={channels} />
        <CardSpacer />
        <ImportSection channels={channels} initialJobs={jobs} />
        <CardSpacer />
        <DeployMessagesCard />
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};

export default Component;
